<div class="image-aboutus-banner">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="lg-text">Our Services</h1>
                <!-- <p class="image-aboutus-para">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> -->
            </div>
        </div>
    </div>

</div>

<div class="container">
    <div class="row  pt-3 pb-3" style="text-align: justify;">
        <div class="col-lg-4">
            <h3>Warehousing</h3>
            <img class="img-fluid" src="../../../assets/unsplesh/warehousing-min.jpg" alt="img 1">
        </div>
        <div class="col-lg-8">
            <p>
                We take this opportunity to introduce ourselves as a Warehousing and C&f agent in Gujarat. We do
                Clearing & Forwarding / Consignee work i.e. handling, storing and delivering the stocks of various
                esteemed companies for over 25 years. We have pleasure in offering our services for the same.

                We and our associates are handling operations of National / Multinational Companies for Chemicals,
                Consumer Products, Pharmaceuticals, Electronics & Electricals, Industrial Products, Food Products etc.
                from Piplaj (Narol) & Kamod (Aslali) – Ahmedabad for The State of Gujarat.

                We are holding over 75000 sq. ft. area from where we operate with fleet of 2 Eichers, 2 407’s & 4 three
                wheelers with our associates. We have a committed team of staff to handle your products to the
                satisfaction of both You and Your Customers.

                We provide customized services as per the clients needs like palletized operation using hydraulic
                forklifts, etc. We have the experience in storing & handling many chemical products of many companies.
            </p>
        </div>
    </div>
    <div class="row  pt-3 pb-3" style="text-align: justify;">

        <div class="col-lg-8">
            <p>
                We are one of the leading Transport and having our network in Gujarat. We have mastered the art of
                transportation, safe storage, On time delivery, perfect handling of cargo, emergencies and keeping
                Health Safety Security and Environment in mind

                We operate mainly in Gujarat. We are specialized in local services i.e. Local Delivery and
                Transportation Booking. We carry industrial goods such as Drums, Bags, Heavy and Light Machinery,
                Delicate Items, etc. Our trucks are engineered so there are no damages to the consignment as there are
                no sharp edges, nails and any other building material to damage anything. The flooring is specially
                fabricated so that all the shocks of the roads and the braking is absorbed and is not passed on to the
                delicate items like electronic goods etc. For the rainy season the trucks are equipped with the best
                quality tarpaulins and PVC sheets to protect the goods from rain.. The company has experienced and
                dedicated staff to ensure customer satisfaction.
            </p>
        </div>
        <div class="col-lg-4">
            <h3>Logistics</h3>
            <img class="img-fluid" src="../../../assets/unsplesh/transport01-min.jpg" alt="img 1">
        </div>
    </div>
    <div class="row  pt-3 pb-3" style="text-align: justify;">
        <div class="col-lg-4">
            <h3>3PL Joint Venture</h3>
            <img class="img-fluid" src="../../../assets/unsplesh/deal01-min.jpg" alt="img 1">
        </div>
        <div class="col-lg-8">
            <p>
                In Warehousing, Product is “our space” and we are placed in Logistics Field.
                Logistics is the management of the flow of goods, information and other resources, including energy and
                people, between the point of origin and the point of consumption in order to meet the requirements of
                consumers (frequently, and originally, military organizations). Logistics involves the integration of
                information, transportation, inventory, warehousing, material-handling, and packaging, and occasionally
                security. Logistics is a channel of the supply chain which adds the value of time and place utility.
                Today the complexity of production logistics can be modeled, analyzed, visualized and optimized by plant
                simulation software.Role of 3pl players, facilitate the movement of materials from suppliers to
                manufactures, and finished products from manufactures to distributors and retailers. They provide
                end-to-end logistics services such as transportation, warehousing, CFS / LCD , cross-docking, packaging
                and freight forwarding. They are also indirectly involved in activities for controlling the flow
                processing, and inventory management.
            </p>
        </div>
    </div>
    <div class="row  pt-3 pb-3" style="text-align: justify;">

        <div class="col-lg-8">
            <p>
                When companies are cautiously preparing for new challenges in the business environment. They are either
                working on an innovative business model in their respective industries or are looking at enhancing the
                performance on existing models through new and innovative methods .Post a survey we saw the express need
                and decided to reinvent the standards of service for corporate stay solutions providing our clients with
                Premium Service at a No – Frills Price… They are defined by a sensibility of intimate design and by
                their varied and eclectic culinary experiences, impeccable service and authenticity. Unmatched standards
                of service, that ensures a complete redefinition of service experience. Our furnished apartments include
                outstanding services, amenities and are fully equipped to meet the needs of every busy professional.We
                bring you the best in Guest Houses, at top most locations across India. Be it Paldi (Heart of the city)
                .Our Accomodation facilities offer comfort, affordability and safety to discerning travelers.
            </p>
        </div>
        <div class="col-lg-4">
            <h3>Other</h3>
            <img class="img-fluid" src="../../../assets/unsplesh/office01-min.jpg" alt="img 1">
        </div>
    </div>
</div>