import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-client-carousel',
  templateUrl: './client-carousel.component.html',
  styleUrls: ['./client-carousel.component.scss']
})
export class ClientCarouselComponent implements OnInit {


  images = [
    {path: '../../../assets/client/1.png'},
    {path: '../../../assets/client/2.png'},
    {path: '../../../assets/client/3.png'},
    {path: '../../../assets/client/4.png'},
    {path: '../../../assets/client/5.png'},
    {path: '../../../assets/client/6.png'},
    {path: '../../../assets/client/7.png'},
    {path: '../../../assets/client/8.png'},
    {path: '../../../assets/client/9.png'},
    {path: '../../../assets/client/10.png'},
    {path: '../../../assets/client/11.png'},
    {path: '../../../assets/client/12.png'},
    {path: '../../../assets/client/13.png'},
    {path: '../../../assets/client/14.png'},
    {path: '../../../assets/client/15.png'},
    {path: '../../../assets/client/16.png'},
    {path: '../../../assets/client/17.png'},
    {path: '../../../assets/client/18.png'},
    {path: '../../../assets/client/19.png'},
    {path: '../../../assets/client/20.png'},
    {path: '../../../assets/client/21.png'},
    {path: '../../../assets/client/22.png'},
    {path: '../../../assets/client/23.png'},
    {path: '../../../assets/client/24.png'},
    {path: '../../../assets/client/25.png'},
    {path: '../../../assets/client/26.png'},
    {path: '../../../assets/client/27.png'},
    {path: '../../../assets/client/28.png'},
    {path: '../../../assets/client/29.png'},
    {path: '../../../assets/client/30.png'},
    {path: '../../../assets/client/31.png'},
   
    
]
  constructor() { }

  ngOnInit(): void {
  }

}
