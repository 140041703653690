<div class="container">
    <div class="row">
        <div class="col-lg-4">
            <img class="img-fluid" src="../../../assets/unsplesh/deal01-min.jpg" alt="img 1">
        </div>
        <div class="col-lg-8">
            <h3 class="content">
                In Warehousing, Product is “our space” and we are placed in Logistics Field.
            </h3>
            <p class="content secondary semi-bold">
                Logistics is the management of the flow of goods, information and other resources, including energy and
                people, between the point of origin and the point of consumption in order to meet the requirements of
                consumers (frequently, and originally, military organizations). Logistics involves the integration of
                information, transportation, inventory, warehousing, material-handling, and packaging, and occasionally
                security. Logistics is a channel of the supply chain which adds the value of time and place utility.
                Today the complexity of production logistics can be modeled, analyzed, visualized and optimized by plant
                simulation software.
            </p>
        </div>
    </div>
    <div class="row">

        <div class="col-lg-12">
            <p class="content secondary semi-bold">
                Role of 3pl players, facilitate the movement of materials from suppliers to manufactures, and finished
                products from manufactures to distributors and retailers. They provide end-to-end logistics services
                such as transportation, warehousing, CFS / LCD , cross-docking, packaging and freight forwarding. They
                are also indirectly involved in activities for controlling the flow processing, and inventory
                management.

                Third-party logistics involves the utilization of external organizations to execute logistics activities
                that have traditionally been performed within an organization itself. According to this definition,
                third party logistics includes any form of outsourcing of logistics activities previously performed
                in-house. For example, if a company with its own transport facilities decides to employ external
                warehouse specialists, this would be an example of third party logistics.
            </p>
            <p class="content secondary semi-bold">
                <b>However, A 3pl player does not necessarily have to provide all of these services and need not cater
                    to all logistics requirements of a company. As it is a contract-based, responsibility of the company
                    and 3pl player are decided in advance. By outsourcing logistics services, companies can focus on
                    their core competence and save on capital investments, such as warehouses and IT platforms, usually
                    require large investments.</b>
            </p>
            <p class="content secondary semi-bold">
                Shah Logistics provides 3PL facilties. It includes following services which come under Third Party
                Logistics such as,competence instead of competencies.
            </p>
            <ul>
                <li><a class="card-link secondary semi-bold" [routerLink]="'/warehousing'">Warehousing &#8608;</a></li>
                <li><a class="card-link secondary semi-bold" [routerLink]="'/logistics'">Logistics &#8608;</a></li>

            </ul>
         
        </div>
    </div>
</div>