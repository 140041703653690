

<div class="container">
  <h2 style="text-align: center;margin: 30px;" class="text-warning bold">
    Products of Esteem Companies Which We Keep in Our Warehouse
  </h2>

    <carousel
    [images]="images" [loop]="true" [autoplay]="true" [height]="120" [arrows]="false" [lightDOM]="true">
</carousel>
</div>