<div class="image-aboutus-banner">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="lg-text">Contact Us</h1>
        <!-- <p class="image-aboutus-para">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> -->
      </div>
    </div>
  </div>

</div>

<div class="container">
  <!-- <h3 class="text-center text-uppercase">contact us</h3> -->
  <div class="row pt-5">
    <div class="col-lg-4">
      <ul style="list-style-type: none;">
        <li class="pt-3">
          <i class="fa fa-phone" aria-hidden="true"></i>&nbsp;&nbsp;
          <a style="color: black;font-weight: bold;" href="tel: 9879593405">+91-9879593405</a>
        </li>
        <li class="pt-3">
          <i class="fa fa-envelope" aria-hidden="true"></i>&nbsp;&nbsp;
          <a style="color: black;font-weight: bold;"
            href="mailto: connect@shahwarehouse.com">connect@shahwarehouse.com</a>
        </li>

      </ul>




    </div>
    <div class="col-lg-8">

      <form>
        <div class="form-group">
          <label for="exampleFormControlInput1 " class=" semi-bold secondary">Name</label>
          <input type="text" maxlength="50" class="form-control" id="exampleFormControlInput1" placeholder="your name">
        </div>
        <div class="form-group">
          <label for="exampleFormControlInput2" class=" semi-bold secondary">Phone</label>
          <input type="tel" maxlength="50" class="form-control" id="exampleFormControlInput2" placeholder="0000000000">
        </div>
        <div class="form-group">
          <label for="exampleFormControlInput3" class=" semi-bold secondary">Email</label>
          <input type="email" class="form-control" id="exampleFormControlInput3" placeholder="name@example.com">
        </div>


        <div class="form-group">
          <label for="exampleFormControlTextarea1" class=" semi-bold secondary">Message</label>
          <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
        </div>
        <button type="submit" class="btn btn-outline-warning secondary">Send</button>
      </form>
    </div>

  </div>

  <div class="row ">

    <div class="col-lg-4 pt-5 pb-3">
      <div class="mapouter">
        <div class="gmap_canvas"><iframe class="gmap_iframe" width="100%" frameborder="0" scrolling="no"
            marginheight="0" marginwidth="0"
            src="https://maps.google.com/maps?width=437&amp;height=400&amp;hl=en&amp;q=Shah Warehouse&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe><a
            href="https://www.fnfgo.com/">Friday Night Funkin Mods</a></div>
        <style>
          .mapouter {
            position: relative;
            text-align: right;
            width: 100%;
            height: 400px;
          }

          .gmap_canvas {
            overflow: hidden;
            background: none !important;
            width: 100%;
            height: 400px;
          }

          .gmap_iframe {
            height: 400px !important;
          }
        </style>
      </div>
    </div>

    <div class="col-lg-4 pt-5 pb-3">
      <div class="mapouter">
        <div class="gmap_canvas"><iframe class="gmap_iframe" width="100%" frameborder="0" scrolling="no"
            marginheight="0" marginwidth="0"
            src="https://maps.google.com/maps?width=437&amp;height=400&amp;hl=en&amp;q=SHAH WAREHOUSE PIPLEJ&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe><a
            href="https://www.fnfgo.com/">FNF Mods</a></div>
        <style>
          .mapouter {
            position: relative;
            text-align: right;
            width: 100%;
            height: 400px;
          }

          .gmap_canvas {
            overflow: hidden;
            background: none !important;
            width: 100%;
            height: 400px;
          }

          .gmap_iframe {
            height: 400px !important;
          }
        </style>
      </div>
    </div>



    <div class="col-lg-4 pt-5 pb-3">
      <div class="mapouter">
        <div class="gmap_canvas"><iframe class="gmap_iframe" width="100%" frameborder="0" scrolling="no"
            marginheight="0" marginwidth="0"
            src="https://maps.google.com/maps?width=437&amp;height=400&amp;hl=en&amp;q=Shah Warehouse kamod&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe><a
            href="https://www.fnfgo.com/">Friday Night Funkin Mods</a></div>
        <style>
          .mapouter {
            position: relative;
            text-align: right;
            width: 100%;
            height: 400px;
          }

          .gmap_canvas {
            overflow: hidden;
            background: none !important;
            width: 100%;
            height: 400px;
          }

          .gmap_iframe {
            height: 400px !important;
          }
        </style>
      </div>
    </div>

  </div>
</div>