<header class="masthead mb-auto">
    <div class="container">

        <!-- <div class="inner"> -->
            <!-- <h3 class="masthead-brand">Cover</h3> -->
            <nav class="nav nav-masthead justify-content-between">
                <a class="nav-link text-warning semi-bold" href="tel: 9879593405"> <i class="fa fa-phone"></i>&nbsp;&nbsp;9879593405</a>
                <a class="nav-link text-warning semi-bold" href="mailto: connect@shahwarehouse.com"><i class="fa fa-envelope"></i>&nbsp;&nbsp;connect@shahwarehouse.com</a>
                <!-- <a class="nav-link" href="#">Contact</a> -->
            </nav>
        <!-- </div> -->
    </div>
</header>