import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-warehousing',
  templateUrl: './warehousing.component.html',
  styleUrls: ['./warehousing.component.scss']
})
export class WarehousingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
