<div class="container">
    <div class="row">
        <div class="col-lg-4">
            <img class="img-fluid" src="../../../assets/unsplesh/transport01-min.jpg" alt="img 1">
        </div>
        <div class="col-lg-8">
            <p class="content secondary semi-bold">
                We are one of the leading Transport and having our network in Gujarat. We have mastered the art of
                transportation, safe storage, On time delivery, perfect handling of cargo, emergencies and keeping
                Health Safety Security and Environment in mind

                We operate mainly in Gujarat. We are specialized in local services i.e. Local Delivery and
                Transportation Booking. We carry industrial goods such as Drums, Bags, Heavy and Light Machinery,
                Delicate Items, etc. Our trucks are engineered so there are no damages to the consignment as there are
                no sharp edges, nails and any other building material to damage anything. The flooring is specially
                fabricated so that all the shocks of the roads and the braking is absorbed and is not passed on to the
                delicate items like electronic goods etc. For the rainy season the trucks are equipped with the best
                quality tarpaulins and PVC sheets to protect the goods from rain.. The company has experienced and
                dedicated staff to ensure customer satisfaction.
            </p>
        </div>
    </div>
    <!-- <div class="row">

        <div class="col-lg-12">
            <p class="content">
                We provide customized services as per the clients needs like palletized operation using hydraulic
                forklifts, etc. We have the experience in storing & handling many chemical products of many companies.
            </p>
            <ul>
                <li>Huge Warehousing and Storage Houses (Area: 75000 sq. ft (Approx))</li>
                <li> Public or Contract Warehousing</li>
                <li>Good Communication (Phone or Email Follow-ups)</li>
                <li>Customized Pick and Pack</li>
                <li>Loading and Unloading of Containers or Drums</li>
                <li>Computerised Inventory Systems</li>
                <li>Generating Detailed Inventory Reports</li>
                <li>Temporary or Long-run Storage</li>
                <li>Drum or Container Inspections</li>
                <li>Transportation Services (Transport Booking and other Transportation within Gujarat )</li>
                <li>24×7 Security Checks</li>
                <li>Service to the Satisfaction</li>
            </ul>









            <p class="content">


                It will be our pleasure to be associated with you and look forward to meeting you on your visit.
            </p>
        </div>
    </div> -->
</div>