<app-main-carousel></app-main-carousel>


<!-- Services Starts -->

<div class="container">
  <h2 style="text-align: center;margin: 30px;" class="text-warning bold">
    Services We Offer
  </h2>
  <div class="row pb-5">
    <div class="col-lg-3">
      <div class="card" style="align-items: center;">
        <i class="fa fa-cubes fa-5x text-icon" aria-hidden="true"></i>
        <h5 class="card-title text-warning">Warehousing</h5>
        <p class="card-text secondary semi-bold">Our value added Services on warehousing are repacking, bar coding and Shrink wrapping.
        </p>
        <a [routerLink]="'/warehousing'" class="card-link text-warning">Read More &#8608; </a>
      </div>
    </div>
    <div class="col-lg-3">
      <div class="card" style="align-items: center;">
        <i class="fa fa-truck fa-5x text-icon" aria-hidden="true"></i>
        <h5 class="card-title text-warning">Logistics</h5>
        <p class="card-text secondary semi-bold"> We are one of the leading Transport and having our network in the West of the country.</p>
        <a [routerLink]="'/logistics'" class="card-link text-warning">Read More &#8608; </a>
      </div>
      
    </div>
    <div class="col-lg-3">
      <div class="card" style="align-items: center;">
        <i class="fa fa-handshake-o fa-5x text-icon" aria-hidden="true"></i>
        <h5 class="card-title text-warning">3PL Joint Venture</h5>
        <p class="card-text secondary semi-bold">To facilitate the movement of materials from suppliers to manufactures, and finished products from manufactures to distributors and retailers.</p>
        <a [routerLink]="'/jointventure'" class="card-link text-warning">Read More &#8608; </a>
      </div>
      
    </div>
    <div class="col-lg-3">
      <div class="card" style="align-items: center;">
        <i class="fa fa-building-o fa-5x text-icon" aria-hidden="true"></i>
        <h5 class="card-title text-warning">Other</h5>
        <p class="card-text secondary semi-bold"> We are into business of providing Office space Facilities to corporate / Shipping Company / MNC at Paldi , Ellisbridge.</p>
        <a  [routerLink]="'/other'" class="card-link text-warning">Read More &#8608; </a>
      </div>
      
    </div>
    
   
  </div>
  <!-- <p class="mt-0 pt-0" style="text-align: center;vertical-align: middle;"> <a [routerLink]="'/services'"> know more</a>
  </p> -->
  <hr class="my-1 bg-warning">
  <hr class="my-1 bg-warning">
  <!-- <p style="padding-top:20px;text-align: center;">
    <a class="btn btn-outline-warning btn-lg" [routerLink]="'/services'" role="button">Know More</a>
  </p> -->

</div>


<!-- Services Ends -->

<!-- About Start -->

<div class="container">
  <div class="jumbotron">


    <h2 style="text-align: center;" class="text-warning bold">While the company is about 32 years young</h2>
    <p class="lead secondary semi-bold" style="text-align: justify;font-family: 'Klee One', cursive;">Has dealt with – as the old adage that
      goes; from Pin to Piano, and beyond… Where priorities have not
      changed – priorities to excel, commit, customer satisfaction, quality, teamwork and more. Has many ‘firsts’ and
      ‘ones’ to its credit, in addition to the word ‘pioneer,’ being tagged to its very existence – more often than not;
      Shah Warehouse, has always, and consciously so, kept profits its least priority.Today, Shah Warehouse and
      associates
      treasure its enormous goodwill and credibility and consider them as its biggest achievement.

      And the credit for this goes to Shree Jitendrabhai & Shree Himanshubhai who thought not ONLY of Tomorrow, but even
      mere future; and in fact, there after.

      And there lies the secret of Shah Warehouse success i.e. sustained hard work coupled with vision and foresight in
      critical situations also.</p>
    <!-- <p class="lead" style="text-align: center;">
      <a class="btn btn-outline-warning btn-lg" [routerLink]="'/about'" role="button">About Us</a>
    </p> -->

  </div>
  <p style="text-align: center;font-weight: 600;"> <a class="text-warning" [routerLink]="'/about'"> Want to know more about us &#8608;</a></p>
  <hr class="my-1 bg-warning">
  <hr class="my-1 bg-warning">
</div>

<!-- About End -->
<app-client-carousel></app-client-carousel>