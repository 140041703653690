import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-carousel',
  templateUrl: './main-carousel.component.html',
  styleUrls: ['./main-carousel.component.scss']
})
export class MainCarouselComponent implements OnInit {

  // images = [944, 1011, 984].map((n) => `https://picsum.photos/id/${n}/900/500`);

  images = [
    // {path: '../../../assets/main/Size01_01-min.jpg'},
    // {path: '../../../assets/main/Size01_02-min.jpg'},
    // {path: '../../../assets/main/Size01_03-min.jpg'},
    // {path: '../../../assets/main/Size01_04-min.jpg'},
    // {path: '../../../assets/main/Size01_05-min.jpg'},
    // {path: '../../../assets/main/Size01_06-min.jpg'},
    // {path: '../../../assets/main/Size01_07-min.jpg'},
    // {path: '../../../assets/main/Size01_08-min.jpg'},
    // {path: '../../../assets/main/Size01_09-min.jpg'},
    // {path: '../../../assets/main/Size01_10-min.jpg'},
    {path: '../../../assets/mainslider/img02.jpg'},
    {path: '../../../assets/mainslider/img03.jpg'},
    {path: '../../../assets/mainslider/img04.jpg'}
    
]

  constructor() { }

  ngOnInit(): void {
  }

}
