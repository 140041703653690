<div class="container">
    <div class="row">
        <div class="col-lg-4">
            <img class="img-fluid" src="../../../assets/unsplesh/warehousing-min.jpg" alt="img 1">
        </div>
        <div class="col-lg-8">
            <p class="content secondary semi-bold">
                We take this opportunity to introduce ourselves as a Warehousing and C&f agent in Gujarat. We do
                Clearing &
                Forwarding / Consignee work i.e. handling, storing and delivering the stocks of various esteemed
                companies
                for over 25 years. We have pleasure in offering our services for the same.

                We and our associates are handling operations of National / Multinational Companies for Chemicals,
                Consumer
                Products, Pharmaceuticals, Electronics & Electricals, Industrial Products, Food Products etc. from
                Piplaj
                (Narol) & Kamod (Aslali) – Ahmedabad for The State of Gujarat.

                We are holding over 75000 sq. ft. area from where we operate with fleet of 2 Eichers, 2 407’s & 4 three
                wheelers with our associates. We have a committed team of staff to handle your products to the
                satisfaction
                of both You and Your Customers.
            </p>
        </div>
    </div>
    <div class="row">

        <div class="col-lg-12">
            <p class="content secondary semi-bold">
                We provide customized services as per the clients needs like palletized operation using hydraulic
                forklifts, etc. We have the experience in storing & handling many chemical products of many companies.
            </p>
            <ul class="text-warning semi-bold">
                <li>Huge Warehousing and Storage Houses (Area: 75000 sq. ft (Approx))</li>
                <li> Public or Contract Warehousing</li>
                <li>Good Communication (Phone or Email Follow-ups)</li>
                <li>Customized Pick and Pack</li>
                <li>Loading and Unloading of Containers or Drums</li>
                <li>Computerised Inventory Systems</li>
                <li>Generating Detailed Inventory Reports</li>
                <li>Temporary or Long-run Storage</li>
                <li>Drum or Container Inspections</li>
                <li>Transportation Services (Transport Booking and other Transportation within Gujarat )</li>
                <li>24×7 Security Checks</li>
                <li>Service to the Satisfaction</li>
            </ul>









            <p class="content secondary semi-bold">


            It will be our pleasure to be associated with you and look forward to meeting you on your visit.
           </p>
        </div>
    </div>
</div>