<!-- <div class="jumbotron" style="margin-bottom:0"> -->
<div class="container border-bottom footer">
    <footer class=" pt-5 ">
        <hr class="my-1 bg-warning">
        <hr class="my-1 bg-warning">
        <div class="row">
            <div class="col-md-3 col-sm-12 mb-3 text-center">
                <img style="padding: 10px;" src="../../../assets/logo-1-300x45.png" width="270px" height="60px" alt="logo">
            </div>
            <div class="col-md-9 col-sm-12">

                <div class="col-md-4 col-sm-6 col-6 p-3 float-left mb-4">
                    <h5 style="cursor: pointer;" (click)="clickonMap(1)" class="mb-4 bold text-warning text-uppercase">
                        Regd. office &nbsp;<i class="fa fa-map-marker" aria-hidden="true"></i></h5>
                    <p class="secondary bold">701, Haridarshan (Ground Floor),
                        B/s. Panchshil Enclave,
                        Nr. Suvidha Cross Roads,
                        Paldi, Ahmedabad - 380007
                    </p>
                </div>

                <div class="col-md-4 col-sm-6 col-6 p-3 mb-4 float-left">
                    <h5 style="cursor: pointer;" (click)="clickonMap(2)" class="mb-4 bold text-warning text-uppercase">
                        Warehouse 1 &nbsp;<i class="fa fa-map-marker" aria-hidden="true"></i></h5>
                    <p class="secondary bold">Plot No. 300, B/h. Hariyana Hotel,
                        Opp. Ganesh Nagar Bus Stand, Piplej
                        Ahmedabad - 382405
                    </p>
                </div>

                <div class="col-md-4 col-sm-6 col-6 mb-4 p-3 float-left">
                    <h5 style="cursor: pointer;" (click)="clickonMap(3)" class="mb-4 bold text-warning text-uppercase">
                        Warehouse 2 &nbsp;<i class="fa fa-map-marker" aria-hidden="true"></i></h5>
                    <p class="secondary bold"> Survey No. 166,Kamod Cow Circle,
                        Aslali to Sanathal Ring Road,
                        Kamod Village,
                        Ahmedabad - 382405
                    </p>
                </div>



            </div>
            <div class="col-md-12">
                <div class="py-2 d-flex justify-content-center align-items-center">
                    <p class="text-warning semi-bold">Copyright © 2021 Shah Warehouse All rights reserved.</p>
                </div>
            </div>
        </div>
    </footer>
</div>
<!-- </div> -->