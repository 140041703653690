import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './components/about/about.component';
import { ContactComponent } from './components/contact/contact.component';
import { HomeComponent } from './components/home/home.component';
import { JointventureComponent } from './components/jointventure/jointventure.component';
import { LogisticsComponent } from './components/logistics/logistics.component';
import { OtherComponent } from './components/other/other.component';
import { ServicesComponent } from './components/services/services.component';
import { WarehousingComponent } from './components/warehousing/warehousing.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'warehousing', component: WarehousingComponent },
  { path: 'logistics', component: LogisticsComponent },
  { path: 'jointventure', component: JointventureComponent },
  { path: 'other', component: OtherComponent },
  { path: 'contactus', component: ContactComponent },
  // { path: 'contactus', component: ContactComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
