<div class="content-outer-container">
    <div class="content-inner-container">

        <app-header></app-header>
        <app-navbar></app-navbar>
        <router-outlet></router-outlet>
    </div>
</div>
<div class="footer-container">
<div class="footer-content">

    <app-footer></app-footer>
</div>
</div>
<div class="scroll-to-top" [ngClass]="{'show-scrollTop': windowScrolled}">
    <button type="button" class="btn active" data-toggle="button" aria-pressed="true" (click)="scrollToTop()">
        <i class="fa fa-chevron-up"></i>
    </button>
</div>