<div class="container">
    <div class="row">
        <div class="col-lg-4">
            <img class="img-fluid" src="../../../assets/unsplesh/office01-min.jpg" alt="img 1">
        </div>
        <div class="col-lg-8">

            <p class="content secondary semi-bold">
                When companies are cautiously preparing for new challenges in the business environment. They are either
                working on an innovative business model in their respective industries or are looking at enhancing the
                performance on existing models through new and innovative methods .Post a survey we saw the express need
                and decided to reinvent the standards of service for corporate stay solutions providing our clients with
                Premium Service at a No – Frills Price… They are defined by a sensibility of intimate design and by
                their varied and eclectic culinary experiences, impeccable service and authenticity. Unmatched standards
                of service, that ensures a complete redefinition of service experience. Our furnished offices include
                outstanding services, amenities and are fully equipped to meet the needs of every busy professional.We
                bring you the best in office, at top most locations across Ahmedabad. Be it Paldi (Heart of the city)
                .Our office facilities offer comfort, affordability and safety to companies.
            </p>
        </div>
    </div>
    <div class="row">

        <div class="col-lg-12">
            <p class="content secondary semi-bold">
                We can also provide Guest House Facilities to corporate / Shipping Company / MNC at Ellisbridge with A/c
                and non A/c with attach toilet and all others facilities. It also say that this is one of the best place
                to stay & enjoy at the city life due to city location
            </p>
            <p class="content secondary semi-bold">
                When companies are cautiously preparing for new challenges in the business environment. They are either
                working on an innovative business model in their respective industries or are looking at enhancing the
                performance on existing models through new and innovative methods .Post a survey we saw the express need
                and decided to reinvent the standards of service for corporate stay solutions providing our clients with
                Premium Service at a No – Frills Price… They are defined by a sensibility of intimate design and by
                their varied and eclectic culinary experiences, impeccable service and authenticity. Unmatched standards
                of service, that ensures a complete redefinition of service experience. Our furnished apartments include
                outstanding services, amenities and are fully equipped to meet the needs of every busy professional.We
                bring you the best in Guest Houses, at top most locations across India. Be it Paldi (Heart of the city)
                .Our Accomodation facilities offer comfort, affordability and safety to discerning travelers.
            </p>
           

        </div>
    </div>
</div>