import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { AppComponent } from './app.component';
import { NgbModule, NgbDropdownModule, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { IvyCarouselModule } from 'angular-responsive-carousel';

import { HomeComponent } from './components/home/home.component';
import { AboutComponent } from './components/about/about.component';
import { ServicesComponent } from './components/services/services.component';
import { ContactComponent } from './components/contact/contact.component';
import { ServiceDetailComponent } from './components/service-detail/service-detail.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { HeaderComponent } from './shared/header/header.component';
import { MainCarouselComponent } from './shared/main-carousel/main-carousel.component';
import { ClientCarouselComponent } from './shared/client-carousel/client-carousel.component';
import { FormsModule } from '@angular/forms';
import { WarehousingComponent } from './components/warehousing/warehousing.component';
import { OtherComponent } from './components/other/other.component';
import { JointventureComponent } from './components/jointventure/jointventure.component';
import { LogisticsComponent } from './components/logistics/logistics.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    ServicesComponent,
    ContactComponent,
    ServiceDetailComponent,
    NavbarComponent,
    FooterComponent,
    HeaderComponent,
    MainCarouselComponent,
    ClientCarouselComponent,
    WarehousingComponent,
    OtherComponent,
    JointventureComponent,
    LogisticsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    IvyCarouselModule,
    FormsModule,
    NgbDropdownModule,

  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }, NgbDropdown],
  bootstrap: [AppComponent]
})
export class AppModule { }
