import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  clickonMap(id: number) {
    console.log("ID = " + id);
    if (id == 1) {

      window.open('https://goo.gl/maps/Z59KHSXUSRmy2rdb6');
    }
    else if (id ==  2) {

      window.open('https://goo.gl/maps/PubAaEPX9CwBbetaA');
    }
    else if (id ==  3) {

      window.open('https://goo.gl/maps/yXRRDqh3EVPhPrGKA');
    }
  }

}
