<nav class="navbar navbar-expand-lg navbar-light mb-0 pt-3 ">

  <div class="container">
    <!-- <a class="navbar-brand" [routerLink]="'.'">Responsive navbar</a> -->
    <img style="padding: 10px;" src="../../../assets/logo-1-300x45.png" alt="logo">

    <!-- Step 3: Toggle the value of the property when the toggler button is clicked. -->
    <button class="navbar-toggler" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">
      &#9776;
    </button>


    <!-- Step 2: Add the ngbCollapse directive to the element below. -->
    <div style="margin-left: 0px;padding-left: 10px;" [ngbCollapse]="isMenuCollapsed"
      class="collapse navbar-collapse justify-content-center bg-warning">
      <ul class="navbar-nav">
        <li class="nav-item">
          <!-- Step 4: Close the menu when a link is clicked. -->
          <a class="nav-link" [routerLink]="'/home'" routerLinkActive="active" (click)="isMenuCollapsed = true"> <b>
              Home</b></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="'/about'" routerLinkActive="active" (click)="isMenuCollapsed = true"><b>
              About</b></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="'/warehousing'" routerLinkActive="active" (click)="isMenuCollapsed = true"><b>
            Warehousing</b></a>
        </li>
       
        <li class="nav-item">
          <a class="nav-link" [routerLink]="'/logistics'" routerLinkActive="active" (click)="isMenuCollapsed = true"><b>
            Logistics</b></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="'/jointventure'" routerLinkActive="active" (click)="isMenuCollapsed = true"><b>
            3PL Joint Venture</b></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="'/other'" routerLinkActive="active" (click)="isMenuCollapsed = true"><b>
            Other</b></a>
        </li>

        <li class="nav-item">
          <a class="nav-link" [routerLink]="'/contactus'" routerLinkActive="active" (click)="isMenuCollapsed = true"><b>
              Contact Us</b></a>
        </li>

      </ul>
    </div>
  </div>

</nav>