<div class="image-aboutus-banner">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="lg-text">About Us</h1>
                <!-- <p class="image-aboutus-para">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> -->
            </div>
        </div>
    </div>

</div>

<div class="aboutus-secktion paddingTB60">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <h1 class="strong">Who we are and<br>what we do</h1>
                <!-- <p class="lead">This is the world's leading portal for<br>easy and quick </p> -->
            </div>
            <div class="col-md-6 ">
                <p class="content secondary semi-bold">
                    Today, Shah Warehouse and associates treasure its enormous goodwill and credibility and consider
                    them as its biggest achievement.

                    And the credit for this goes to Shree Jitendrabhai & Shree Himanshubhai who thought not ONLY of
                    Tomorrow, but even mere future; and in fact, there after.
                    And there lies the secret of Shah Warehouse success i.e. sustained hard work coupled with vision and
                    foresight in critical situations also.
                </p>
                <p  class="content secondary semi-bold">

                    Shree Himanshubhai was always ahead of time. He was an institution, for he grew and helped others
                    grow. Today, many people around the country as well as globe are indebted to Shree Himanshubhai for
                    guiding them in their initial years of grounding. His son Naushil today man the legacy of Shree
                    Himanshubhai.

                    Born in 1964, Shree Himanshubhai Shah has been handling a vast array of products, which are
                    incomprehensible to the many and varied distributors that throng the Indian market today.
                </p>
                <p  class="content secondary semi-bold">
                    Shah Warehouse’s customer network is well spread across the country as well as globe, and in
                    particular in the State of Gujarat, where it has a well recognized presence.

                    One of the most flourishing Indian states, with high disposable income, sustained industrial growth,
                    labour friendly environment, higher literacy rate compared to the national average, the largest
                    coastline in the country as well as globe, Gujarat is home to Shah Warehouse now in their Third
                    generation.

                    Amongst the many in-built advantages Shah Warehouse offers customized warehouses for storing
                    products of each of its long list of clientele, which include various national and multi-national
                    companies’s product.
                </p>
                <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla convallis egestas rhoncus. Donec
                    facilisis fermentum sem, ac viverra ante luctus vel. Donec vel mauris quam.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla convallis egestas rhoncus. Donec
                    facilisis fermentum sem, ac viverra ante luctus vel. Donec vel mauris quam. Lorem ipsum dolor sit
                    amet. Nulla convallis egestas rhoncus.</p> -->
            </div>
        </div>

    </div>
</div>